* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0;
  margin: 0;
}

.cover {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -2;
  position: fixed;
}

.line-break {
  white-space: pre-wrap;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

Player {
  z-index: -1;
}

#tsconfetti {
  /* position: relative; */
  transition: 1s ease-out;
  z-index: 10;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 10;
  /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}
/* Add the CSS classes for animation */
/* Add the CSS classes for animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.lyrics-enter {
  animation: fadeInUp 0.3s ease-in-out forwards;
}

.lyrics-exit {
  animation: fadeOutUp 0.3s ease-in-out forwards;
}

.simplebar-placeholder {
  display: none;
}
/* styles.css */
.card-image-container {
  position: relative;
  overflow: hidden;
}

.card-image-container .img {
  transition: transform 0.3s ease;
  transform-origin: center center;
}

.card-image-container:hover .img {
  transform: scale(1.2);
}

.simplebar-placeholder {
  display: none;
}
